import Navbar from "../components/Navbar";
import NavBarMobile from "../components/NavBarMobile";
import CmsPage from "../components/CmsPage";

import { loadPage } from "../lib/api-lib";

function Page({ structure }) {
  return (
    <div>
      <Navbar navigation={structure.navigation} />
      <NavBarMobile navigation={structure.navigation} />
      <CmsPage structure={structure} />
    </div>
  );
}

export async function getStaticProps(context) {
  // console.log(context)
  const data = await loadPage() || false;
  if(data == false) {
    return {
      notFound: true
    };
  } else {
    return {
      props: {
        structure: data,
      },
      revalidate: 10,
    };
  }
}

export default Page;
